import { useState, ReactNode, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

// pages
import { useAuthService } from "../services/AuthService";
import { useUserService } from "../services/UserService";
import Login from '../pages/authentication/Login';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated } = useAuthService();
  //const { userMeList } = useUserService();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      if (isAuthenticated) await localStorage.getItem("persist:root");
    })();
  }, [isAuthenticated]);
  
  if (!isAuthenticated) {
    
    if (pathname === requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
