import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';

// guards
import GuestGuard from '../guards/GuestGuard';
import AuthGuard from '../guards/AuthGuard';
// import RoleBasedGuard from '../guards/RoleBasedGuard';

// components
import LoadingScreen from '../components/LoadingScreen';
import NotificationEdit from '../pages/notification/notificationEdit';



const Loadable = (Component: React.ElementType) => (props: any) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    { path: '/', element: <Navigate to="/advertising" replace /> },
    {
      path: 'auth',
      children: [
        { path: 'login', element: (<GuestGuard> <Login /> </GuestGuard>) },
        { path: 'register', element: (<GuestGuard> <Register /> </GuestGuard>) },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> }
      ]
    },

    {
      path: 'settings',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'password',
          children: [
            { path: '', element: <UserAccount /> },
          ]
        }
      ]
    },
    {
      path: 'dashboard',
      element: (
        // <AuthGuard>
        <DashboardLayout />
        //</AuthGuard>
      ),
      children: [

        {
          path: 'result',
          children: [
            { path: '', element: <User /> },
          ]
        },
      ]
    },
    {
      path: 'advertising',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <AdvertisingList /> },
        { path: 'list', element: <AdvertisingList /> },
        { path: 'new', element: <AdvertisingNew /> },
        { path: 'edit/:advertisingId', element: <AdvertisingEdit /> },
      ]

    }, {
      path: 'Entity',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <EntityList /> },
        { path: 'list', element: <EntityList /> },
        { path: 'new', element: <NewEntity /> },
        { path: 'edit/:entityId', element: <EditEntity /> },
      ]

    }, {
      path: 'player',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <PlayersList /> },
        { path: 'search', element: <PlayersList /> },
        { path: 'import', element: <PlayersImport /> },
      ]

    },
    /* {
       path: 'notification',
       element: (
         //<AuthGuard>
         <DashboardLayout />
         //</AuthGuard>
       ),
       children: [
         { path: '', element: <NotificationList /> },
         { path: 'list', element: <NotificationList /> },
         { path: 'new', element: <NotificationCreate /> },
         { path: 'edit/:notificationId', element: <NotificationEdit /> },
       ]
 
     },*/
    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" replace /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));

// Setting
const UserAccount = Loadable(lazy(() => import('../pages/settings/UserAccount')));
const User = Loadable(lazy(() => import('../pages/dashboard/UserAccount')));


//Player
const PlayersList = Loadable(lazy(() => import('../pages/player/PlayersList')));
const PlayersImport = Loadable(lazy(() => import('../pages/player/PlayersImport')));

//Advertising
const AdvertisingList = Loadable(lazy(() => import('../pages/advertising/advertisingList')));
const AdvertisingNew = Loadable(lazy(() => import('../pages/advertising/AdvertisingNew')));
const AdvertisingEdit = Loadable(lazy(() => import('../pages/advertising/AdvertisingEdit')));

//Entidade
const EntityList = Loadable(lazy(() => import('../pages/admin/entity_management/EntityList')));
const EditEntity = Loadable(lazy(() => import('../pages/admin/entity_management/EditEntity')));
const NewEntity = Loadable(lazy(() => import('../pages/admin/entity_management/NewEntity')));

//Notification
const NotificationList = Loadable(lazy(() => import('../pages/notification/notificationList')));
const NotificationCreate = Loadable(lazy(() => import('../pages/notification/notificationCreate')));

// Errors
const Page500 = Loadable(lazy(() => import('../pages/Page500')));
const NotFound = Loadable(lazy(() => import('../pages/Page404')));
