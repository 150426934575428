import { useAuthAction } from "../redux/actions/authAction";
import { useSelector } from "react-redux";
import { AuthReducerState } from "../redux/reducers/authReducer";
import { DateTime } from "luxon";
import axios from 'axios'
import { Api } from '../apis/Api';
import { useUserAction } from "../redux/actions/userAction";

export const useAuthService = () => {
  const { api } = new Api({ baseURL: `${process.env.REACT_APP_BACKOFFICEAPI_BASEURL}` });

  const { loginAction, logoutAction } = useAuthAction();
  const { loggedIn, expiresAt } = useSelector((state: { auth: AuthReducerState }) => state?.auth);
  const { fetchUserData, clearUserData } = useUserAction();

  /* const api = axios.create({
     baseURL: `${process.env.REACT_APP_BACKOFFICEAPI_BASEURL}`,
   });*/

  return {
    login: async (login: string, password: string): Promise<void> => {
      await localStorage.removeItem('persist:root')
      await api.Login({ login, password }).then(async (authData) => {

        loginAction(
          authData.data?.result.token!

        );
        fetchUserData({
          idLogin: authData.data?.result.id_Login,
          email: authData.data?.result.login!,
          name: authData.data?.result.name!,
          roles: authData.data?.result.roles!,
        }, authData.data.result?.token!);
      });
    },

    logout: async (): Promise<void> => {
      await localStorage.removeItem('persist:root')
      clearUserData()
      logoutAction()

    },

    isAuthenticated: loggedIn


  }
}
