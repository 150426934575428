export type AuthReducerState = {
  loggedIn: boolean;
  accessToken?: string;
  refreshToken?: string;
  expiresIn?: number;
  expiresAt?: string;
};

const initialState: AuthReducerState = {
  loggedIn: false,
  accessToken: undefined,
  refreshToken: undefined,
  expiresIn: undefined,
  expiresAt: undefined,
};

const authReducer = (
  state: AuthReducerState = initialState,
  action: any
): AuthReducerState => {
  switch (action.type) {
    case "LOGIN": {
      return {
        ...state,
        ...action.data,
        loggedIn: true,
      };
    }
    case "LOGOUT": {
      return {
        ...initialState,
        loggedIn:false
      };
    }
    default: {
      return state;
    }
  }
};

export default authReducer;
