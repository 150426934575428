import { useDispatch } from "react-redux";
import { DateTime } from "luxon";

export function useAuthAction() {
  const dispatch = useDispatch();

  return {
    loginAction: (accessToken: string) => {
      dispatch({
        type: "LOGIN",
        data: {
          accessToken: accessToken,
          // refreshToken,
          // expiresAt: expiresAt.toISO(),
        },
      });
    },
    logoutAction: () => {
      dispatch({ type: "LOGOUT" });
    },
  };
}
