import { UserAccessType } from "../../apis/Api";

export type UserReducerState = {
  idLogin?: number,
  name?: string;
  email?: string;
  roles?: string[];
  profileImageUrl?: string;

};

const initialState: UserReducerState = {
  idLogin: undefined,
  name: undefined,
  email: undefined,
  roles: undefined,
  profileImageUrl: undefined
};

const userReducer = (
  state: UserReducerState = initialState,
  action: any
): UserReducerState => {
  switch (action.type) {
    case "FETCH_USER_DATA": {
      return {
        ...state,
        ...action.data,
      };
    }
    case "CLEAR_USER_DATA":{
      return {
        ...initialState,
        ...action.data,
      }
    }
    default: {
      return state;
    }
  }
};

export default userReducer;
