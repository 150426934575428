/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { Advertising } from '../pages/advertising/advertisingList';

export interface AppError {
  code?: string | null;
  message?: string | null;
  stackTrace?: string | null;
}

export interface LoginCriteria {
  login?: string | null;
  password?: string | null;
}

export interface StringIResult {
  message?: string | null;

  /** @format int32 */
  status?: number;
  result?: string | null;
  isSuccessStatusCode?: boolean;
}
export interface AdvertisingListResult {
  message?: string | null;

  /** @format int32 */
  status?: number;
  result?: Advertising[] | null;
  isSuccessStatusCode?: boolean;
}

export enum UserAccessType {
  Unset = 'Unset',
  SysAdmin = 'SysAdmin',
  BackofficeAdmin = 'BackofficeAdmin',
  Financial = 'Financial',
  Sponsor = 'Sponsor',
  PlayerManager = 'PlayerManager',
}

export interface UserViewModel {
  /** @format int64 */
  result: {
    id_Login?: number;
    name?: string | null;
    login?: string | null;
    token?: string | null;
    roles?: string[];
  };
}
export interface Player {
  birthday?: string | null;
  createdByLogin?: number;
  docCpf?: string | null;
  email?: string | null;
  hasGame?: boolean;
  hasSubscription?: boolean;
  id?: number;
  name?: string | null;
  nickName?: string | null;
  phone?: string | null;
}
export interface LegalResponsible {
  doc?: string | null;
  idLogin: number;
  login?: string | null;
  name?: string | null;
  birthday?: string | null;
  phoneNumber?: string | null;
  roles?: string[] | null;
  player?: Player | null;
}
export interface Entity {
  id?: number;
  name?: string | null;
  email?: string | null;
  document?: string | null;
  contractStatus?: number;
  contractType: number;
  creationDate?: string | null;
  zipCode?: string | null;
  district?: string | null;
  address?: string | null;
  houseNumber?: string | null;
  state?: string | null;
  city?: string | null;
  phoneNumber?: string | null;
  addressDetails?: string | null;
  countryId?: number | null;
  idLoginResponsible?: number;
  legalResponsible?: LegalResponsible | null;
}
export interface Country {
  id?: number;
  continentId?: number;
  continentName?: string | null;
  code?: string | null;
  name?: string | null;
}

export interface PlayerUpload {
  line?: string | null;
  status?: boolean;
  message?: string | null;
  error?: boolean;
  errorMessage?: string | null;

  /** @format int64 */
  playerId?: number;

  /** @format int64 */
  loginId?: number;
  teamCode?: string | null;
  name?: string | null;
  nickname?: string | null;
  email?: string | null;
  phone?: string | null;
  doc?: string | null;
}

import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from 'axios';

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams
  extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<
  FullRequestParams,
  'body' | 'method' | 'query' | 'path'
>;

export interface ApiConfig<SecurityDataType = unknown>
  extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker'];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({
    securityWorker,
    secure,
    format,
    ...axiosConfig
  }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({
      ...axiosConfig,
      baseURL: axiosConfig.baseURL || '',
    });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private mergeRequestParams(
    params1: AxiosRequestConfig,
    params2?: AxiosRequestConfig
  ): AxiosRequestConfig {
    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.instance.defaults.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createFormData(input: Record<string, unknown>): FormData {
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      formData.append(
        key,
        property instanceof Blob
          ? property
          : typeof property === 'object' && property !== null
          ? JSON.stringify(property)
          : `${property}`
      );
      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = (format && this.format) || void 0;

    if (
      type === ContentType.FormData &&
      body &&
      body !== null &&
      typeof body === 'object'
    ) {
      requestParams.headers.common = { Accept: '*/*' };
      requestParams.headers.post = {};
      requestParams.headers.put = {};

      body = this.createFormData(body as Record<string, unknown>);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData
          ? { 'Content-Type': type }
          : {}),
        ...(requestParams.headers || {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Futstat - API BackOffice
 * @version 1.0
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  api = {
    /**
     * No description
     *
     * @tags Advertising
     * @name V1AdvertisingCreate
     * @request POST:/api/v1/advertising
     * @secure
     */
    CreateAdvertising: (data: any, params: RequestParams = {}) =>
      this.request<void, AppError>({
        path: `/api/v1/advertising`,
        method: 'POST',
        body: data,
        secure: true,
        format: 'json',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advertising
     * @name V1AdvertisingUpdate
     * @request PUT:/api/v1/advertising
     * @secure
     */
    UpdateAdvertising: (data: any, params: RequestParams = {}) =>
      this.request<void, AppError>({
        path: `/api/v1/advertising`,
        method: 'PUT',
        secure: true,
        body: data,
        format: 'json',
        type: ContentType.Json,
        ...params,
      }),
    /**
     * No description
     *
     * @tags Advertising
     * @name V1AdvertisingFinalize
     * @request PUT:/api/v1/advertising/{id}/finalize
     * @secure
     */ FinishAdvertising: (id: string, params: RequestParams = {}) =>
      this.request<void, AppError>({
        path: `/api/v1/advertising/${id}/finalize`,
        method: 'PUT',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advertising
     * @name V1AdvertisingList
     * @request GET:/api/v1/advertising
     * @secure
     */
    FetchAdvertisings: (params: RequestParams = {}) =>
      this.request<AdvertisingListResult, AppError>({
        path: `/api/v1/advertising`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     *
     * @tags Advertising
     * @name V1AdvertisingListFiltered
     * @request GET:/api/v1/advertising?orderBy=ID&top=value
     * @secure
     */
    FetchAdvertisingsFiltered: (
      orderBy?: any,
      top?: any,
      skip?: number,
      filter?: any,
      statusFilter?: any,
      params: RequestParams = {}
    ) =>
      this.request<AdvertisingListResult, AppError>({
        // path: `/api/v1/advertising?orderby=${'Id asc'}&top=${top}&custom_filter=${filter}&skip=${skip}`,
        path: `/api/v1/advertising?top=${top}&custom_filter=${filter}&skip=${skip}&status_filter=${statusFilter}`,
        method: 'GET',
        type: ContentType.Json,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Advertising
     * @name V1AdvertisingDetail
     * @request GET:/api/v1/advertising/{id}
     * @secure
     */
    FetchSingleAdvertising: (id: string, params: RequestParams = {}) =>
      this.request<StringIResult, AppError>({
        path: `/api/v1/advertising/${id}`,
        method: 'GET',
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Login
     * @name V1LoginCreate
     * @request POST:/api/v1/login
     * @secure
     */
    Login: (data: LoginCriteria, params: RequestParams = {}) =>
      this.request<UserViewModel, AppError>({
        path: `/api/v1/login`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name fetchEntityById
     * @request GET:/api/v1/organization/{id}
     * @secure
     * @response `200` `StringIResult` Success
     * @response `500` `AppError` Server Error
     */
    FetchEntityById: (entityId: string, params: RequestParams = {}) =>
      this.request<any, AppError>({
        path: `/api/v1/organization/${entityId}`,
        method: 'GET',
        secure: true,
        format: 'json',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FindUserByEmail
     * @request GET:/api/v1/users/find-by-email/{email}
     * @secure
     * @response `200` `StringIResult` Success
     * @response `500` `AppError` Server Error
     */
    FindUserByEmail: (email: string, params: RequestParams = {}) =>
      this.request<any, AppError>({
        path: `/api/v1/users/find-by-email/${email}`,
        method: 'GET',
        secure: true,
        format: 'json',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags User
     * @name FindUserByFilter
     * @request GET:/api/v1/users/find-by-email/{email}
     * @secure
     * @response `200` `StringIResult` Success
     * @response `500` `AppError` Server Error
     */
    FindUserByFilter: (customFilter: string, params: RequestParams = {}) =>
      this.request<any, AppError>({
        path: `/api/v1/users?orderby=Id_Login desc&top=2&custom_filter=${customFilter}`,
        method: 'GET',
        secure: true,
        format: 'json',
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name CreateEntity
     * @request POST:/api/v1/organization
     * No description
     * @secure
     **/
    CreateEntity: (data: any, params: RequestParams = {}) =>
      this.request<any, AppError>({
        path: `/api/v1/organization`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * @tags Player
     * @name VPlayerImportPreviewCreate
     * @summary Preview from import
     * @request POST:/api/v{version}/player/import/preview
     * @secure
     * @response `200` `StringIResult` Success
     * @response `500` `AppError` Server Error
     */

    vPlayerImportPreviewCreate: (
      version: string,
      data: {
        ContentType?: string;
        ContentDisposition?: string;
        Headers?: Record<string, string[]>;
        Length?: number;
        Name?: string;
        FileName?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<StringIResult, AppError>({
        path: `/api/v${version}/player/import/preview`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags Organization
     * @name updateEntity
     * @request PUT:/api/v1/organization
     */
    updateEntity: (data: any, params: RequestParams = {}) =>
      this.request<any, AppError>({
        path: `/api/v1/organization`,
        method: 'PUT',
        type: ContentType.Json,
        body: data,
        secure: true,
        format: 'json',
        ...params,
      }),

    /* @tags Player
     * @name VPlayerImportCreate
     * @summary Import new players
     * @request POST:/api/v{version}/player/import
     * @secure
     * @response `200` `StringIResult` Success
     * @response `500` `AppError` Server Error
     */

    vPlayerImportCreate: (
      version: string,
      data: PlayerUpload[],
      params: RequestParams = {}
    ) =>
      this.request<StringIResult, AppError>({
        path: `/api/v${version}/player/import`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     * @tags Entities
     * @name FetchEntities
     * @request GET:/api/v1/organization?orderBy=ID&top=value&skip=value
     * @secure
     */
    FetchEntities: (
      orderBy?: any,
      top?: any,
      skip?: number,
      filter?: string,
      params: RequestParams = {}
    ) =>
      this.request<any, AppError>({
        path: `/api/v1/organization?orderBy=${orderBy}&top=${top}&skip=${skip}&custom_filter=${filter}`,
        method: 'GET',
        type: ContentType.Json,
        secure: true,
        format: 'json',
        ...params,
      }),

    /**
     * No description
     * @tags Countries
     * @name FetchCountries
     * @request GET:/api/v1/geolocation/countries
     * @secure
     */
    FetchCountries: (params: RequestParams = {}) =>
      this.request<any, AppError>({
        path: `/api/v1/geolocation/countries`,
        method: 'GET',
        type: ContentType.Json,
        secure: true,
        format: 'json',
        ...params,
      }),
    /**
     * No description
     * @tags Countries
     * @name FetchCountryById
     * @request GET:/api/v1/geolocation/countries/id
     * @secure
     */
    FetchCountryById: (id: string, params: RequestParams = {}) =>
      this.request<any, AppError>({
        path: `/api/v1/geolocation/countries/${id}`,
        method: 'GET',
        type: ContentType.Json,
        secure: true,
        format: 'json',
        ...params,
      }),

    /* @tags Player
     * @name vPlayerImportPreviewCreate2
     * @summary Import new players
     * @request POST:/api/v{version}/player/import/preview
     * @secure
     * @response `200` `StringIResult` Success
     * @response `500` `AppError` Server Error
     */
    // @ts-ignore
    vPlayerImportPreviewCreate2: (
      version: string,
      data: FormData,
      params: RequestParams = {}
    ) =>
      this.request<StringIResult, AppError>({
        path: `/api/v${version}/player/import/preview`,
        method: 'POST',
        body: data,
        headers: {
          'Content-type': 'multipart/form-data',
        },
        format: 'json',
        ...params,
      }),

          /**
     * No description
     *
     * @tags Player
     * @name V1DeletePlayer
     * @request DELETE:/api/v1/player/{playerId}
     * @secure
     */
    deletePlayer: (playerId: number, params: RequestParams = {}) =>
      this.request<void, AppError>({
        path: `/api/v1/player`,
        method: 'DELETE',
        secure: true,
        body: {id: playerId},
        format: 'json',
        type: ContentType.Json,
        ...params,
      }),
      historyPlayer: (playerId: number, params: RequestParams = {}) =>
        this.request<void, AppError>({
          path: `/api/v1/player/${playerId}/history-teams`,
          method: 'GET',
          secure: true,
          format: 'json',
          type: ContentType.Json,
          ...params,
        }),
  };
}
