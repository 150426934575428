import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import enUSLocales from './enUS.json';
import ptBRLocales from './ptBR.json';
import esESLocales from './esES.json';

const resources = {
  enUS: {
    futstat:enUSLocales
  },
  esES: {
    futstat: esESLocales
  },
  ptBR: {
    futstat: ptBRLocales,
   
  }
}

export const setLanguage = (language: 'ptBR' | 'enUS' | 'esES') => {
  localStorage.setItem('i18nextLng', language)
  i18n.changeLanguage(language);
}

const lng = localStorage.getItem('i18nextLng') || 'ptBR';

export default i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources: resources,
    lng: lng,
    fallbackLng: 'ptBR',
    debug: false,
    interpolation: {
      escapeValue: false,
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        if (format === 'lowercase') return value.toLowerCase();
        if (format === 'capitalize') return `${value.substr(0, 1).toUpperCase()}${value.substr(1)}`;
        return value;
      },
    }
  });

