import { useRef, useState } from 'react';
// material
import { Box, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
// components
import MenuPopover from '../../components/MenuPopover';
//import downArrow from '@iconify/icons-bxs/down-arrow';
import { MIconButton } from '../../components/@material-extend';
import { useTranslation } from 'react-i18next';
import { Icon } from '@iconify/react';

// ----------------------------------------------------------------------

/*const LANGS = [
  {
    value: 'en',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 'de',
    label: 'German',
    icon: '/static/icons/ic_flag_de.svg'
  },
  {
    value: 'fr',
    label: 'French',
    icon: '/static/icons/ic_flag_fr.svg'
  }
  
];*/
const LANGS = [
  {
    value: 'enUS',
    label: 'English',
    icon: '/static/icons/ic_flag_en.svg'
  },
  {
    value: 'esES',
    label: 'Español',
    icon: '/static/icons/ic_flag_es.svg'
  },
  {
    value: 'ptBR',
    label: 'Português',
    icon: '/static/icons/ic_flag_pt_br.svg'
  }
];

// ----------------------------------------------------------------------

export default function LanguagePopover() {

  const { i18n, t } = useTranslation()
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage);

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleChangeLanguage = (lang: string) => {
    i18n.changeLanguage(lang).then()
    setOpen(false)

  }


  return (
    <>
      <MIconButton
        ref={anchorRef}
        onClick={() => setOpen(true)}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
          },

          padding: 0,
          //  width: 44,
          // height: 44,
          ...(open && { /*bgcolor: 'action.selected'*/ bgcolor:'transparent' })
        }}
      >
        {/*<img src={currentLang?.icon} alt={currentLang?.label} />*/}
        <ListItemText primaryTypographyProps={{ variant: 'body2' }} sx={{ paddingRight: .5 }}>
          {t(`futstat:changeLanguage`)}
        </ListItemText>
        <ListItemIcon>
          <Icon icon={"ep:arrow-down"} height={10} width={10} />
        </ListItemIcon>
      </MIconButton>

      <MenuPopover open={open} onClose={() => setOpen(false)} anchorEl={anchorRef.current}>
        <Box sx={{ py: 1 }}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === currentLang?.value}
              onClick={() => handleChangeLanguage(option.value)}
              sx={{ py: 1, px: 2.5 }}
            >
              {/*<ListItemIcon>
                <Box component="img" alt={option.label} src={option.icon} />
          </ListItemIcon>*/}
              <ListItemText primaryTypographyProps={{ variant: 'body2' }}>
                {option.label}
              </ListItemText>
            </MenuItem>
          ))}
        </Box>
      </MenuPopover>
    </>
  );
}
