// routes
import { PATH_PLAYER, PATH_BACKOFFICE } from '../../routes/paths';

// components
import SvgIconStyle from '../../components/SvgIconStyle';
import { Icon } from '@iconify/react';
import editFill from '@iconify/icons-eva/edit-fill';
// ----------------------------------------------------------------------

const getIcon = (name: string) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  colaboradores: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking')
};


const sidebarConfig = [

  {
    subheader: 'Marketing',
    items: [
      {
        title: 'Anúncio',
        path: PATH_BACKOFFICE.advertising.content.list,
        icon: <Icon icon="healthicons:i-schedule-school-date-time" style={{ fontSize: '24px' }} />
      },
     /* {
        title: 'Notificação',
        path: PATH_BACKOFFICE.notification.root,
        icon: ICONS.calendar
      }*/
    ]
  },
  /* {
    subheader: 'Administração',
    items: [
      {
        title: 'Atualizar jogadores',
        path: PATH_ADMIN.admin.root,
        icon: <Icon icon={editFill} style={{ fontSize: '24px' }} />
      },
     /* {
        title: 'Notificação',
        path: PATH_BACKOFFICE.notification.root,
        icon: ICONS.calendar
      }
    ]
  },*/


  /*{
    subheader: 'Assessments',
    items: [
      {
        title: 'Performance',
        path: PATH_BACKOFFICE.assessments.performance.root,
        icon: ICONS.calendar
      }
    ]
  },*/

];

export default sidebarConfig;
