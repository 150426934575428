import { useDispatch } from "react-redux";
import { UserReducerState } from "../reducers/userReducer";
import { AuthReducerState } from "../reducers/authReducer";
import { type } from "os";

export function useUserAction() {
  const dispatch = useDispatch();

  return {
    fetchUserData: (userData: UserReducerState, accessToken: String) => {
      dispatch({
        type: "FETCH_USER_DATA",
        data: {
          idLogin: userData?.idLogin,
          name: userData?.name,
          email: userData?.email,
          token: accessToken,
          roles: userData.roles,
          profileImageUrl: userData?.profileImageUrl,

        }
      })
    },
    clearUserData: () => {
      dispatch({
        type: "CLEAR_USER_DATA",
        data: {
        

        },
      })
    }
  }
}